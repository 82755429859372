<template>
    <CRow>
        <CCol sm="12" lg="3">
            <CInput 
            v-model="form.representatives[0].mail_address.zip_code" 
            class="mb-0" 
            placeholder="Digite o CEP" 
            label="CEP"
            :is-valid="valRepresCEP"
            v-mask="'#####-###'"
            />
        </CCol>
        <CCol sm="12" lg="6">
            <CInput 
            v-model="form.representatives[0].mail_address.address1" 
            class="mb-0" 
            v-uppercase 
            placeholder="Ex.: Rua Alba Valdez" 
            label="Endereço"
            :is-valid="validator"
            />
        </CCol>
        <CCol sm="12" lg="3">
            <CInput 
            v-model="form.representatives[0].mail_address.address_number" 
            class="mb-0" 
            v-uppercase 
            label="Número"
            />
        </CCol>
        <CCol sm="12" lg="5">
            <CInput 
            v-model="form.representatives[0].mail_address.address2" 
            class="mb-0" 
            placeholder="Ex.: Sala 701" 
            label="Complemento"
            />
        </CCol>
        <CCol sm="12" lg="7">
            <CInput 
            v-model="form.representatives[0].mail_address.district" 
            class="mb-0" 
            placeholder="Digite o bairro" 
            label="Bairro"
            :is-valid="validator"
            v-uppercase 
            />
        </CCol>
        <CCol sm="12" lg="9">
            <CInput 
            v-model="form.representatives[0].mail_address.city" 
            class="mb-0" 
            placeholder="Digite a cidade" 
            label="Cidade"
            :is-valid="validator"
            v-uppercase 
            />
        </CCol>
        <CCol sm="12" lg="3">
            <CInput 
            v-model="form.representatives[0].mail_address.uf" 
            class="mb-0" 
            label="UF"
            :is-valid="validatorUF"
            />
        </CCol>           
    </CRow>
</template>

<script>
import functions from '@/functions'
export default {
    name: 'CadEmpresaEndResponsavel',
    props:{
        form:{
            type: Object,
            required: true,
        }
    },
    methods:{
        ...functions,
        valRepresCEP (cep) {      
            if (cep.length===9){
                (async (cep)=>{
                    const con = await this.getAddressZipCode(cep)
                    const {data} = con;
                    this.form.representatives[0].mail_address.address1 = data.logradouro;
                    this.form.representatives[0].mail_address.district = data.bairro;
                    this.form.representatives[0].mail_address.city     = data.localidade;
                    this.form.representatives[0].mail_address.uf       = data.uf;
                    this.form.representatives[0].mail_address.ibge     = data.ibge;
                })(cep)
                return true
            }
            return false
        },
    }

}
</script>

<style>

</style>