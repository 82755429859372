<template>
    <CRow class="form-group-company">
        <CModal title="Aviso!" color="danger" :show.sync="showAlert">
            {{ msg }}
            <template #footer>
                <CButton @click="showAlert = false" color="danger">OK</CButton>
            </template>
        </CModal>
        <CModal title="Aviso!" color="danger" :show.sync="showAlertMsgCliente">
            {{ msgCliente }}
            <template #footer>
                <CButton @click="showAlertMsgCliente = false" color="danger">OK</CButton>
            </template>
        </CModal>
        <CModal size="lg" title="Documento" color="primary" :show.sync="showDoc">
            <pdf :src="srcPdf" />
            <template #footer>
                <CButton @click="showDoc = false" color="primary">Sair</CButton>
                <CButton @click="downloadSCR" color="info">Baixar</CButton>
            </template>
        </CModal>
        <CCol sm="12" class="px-4 mt-3" >
            <CRow>
                <CCol sm="12">
                    <CDataTable
                        hover
                        striped
                        border
                        fixed
                        :items="items"
                        :fields="fields"
                        :items-per-page="5"
                        :noItemsView="noItemsView"
                        pagination
                    >
                        <template #status="{item}">
                            <td>
                                <a>
                                    {{ 
                                        !item.active || !item.token_active ? 
                                        'Inativo' : 
                                        item.procuration ? 'Assinado' : 'Não assinado'
                                    }}
                                </a>
                            </td>
                        </template>
                        <template #createdAt="{item}">
                            <td>                             
                              <a>
                                {{formatDate(item.createdAt)}}
                              </a>
                            </td>                            
                        </template>
                        <template #expiration_date="{item}">
                            <td>                              
                              <a>
                                 {{formatDate(item.expiration_date)}}
                              </a>
                            </td>
                        </template>
                        <template #actions="{item}">
                            <td>                              
                                <CButton
                                    v-if="item.active && item.token_active && item.procuration"
                                    :name=item.id
                                    size="sm"
                                    :key="'vw'+item.id"
                                    color="success"                                    
                                    @click="viewProcuration(item.procuration)"
                                >
                                    <CIcon size="sm" name="cil-magnifying-glass"/>
                                </CButton>
                                &nbsp;
                                <CButton
                                    :name=item.id
                                    size="sm"
                                    :key="'ex'+item.id"
                                    color="danger"
                                    v-if="item.active===true"
                                    @click="cancelProcuration(item.id)"
                                >
                                    <CIcon size="sm" name="cil-ban"/>
                                </CButton>
                                &nbsp;
                                <CButton
                                    :name=item.id
                                    size="sm"
                                    :key="'msg'+item.id"
                                    color="info"
                                    v-if="item.error_msg"
                                    @click="exbMsgCliente(item.error_msg)"                                  
                                >
                                    <CIcon size="sm" name="cil-bell"/>
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                </CCol>
            </CRow>
            <CRow class="align-items-center">
                <CCol sm="12" md="6">
                    <CButton block color="dark"  @click="sendDocuments">
                        Enviar Procuração 
                        <CIcon name="cil-find" />
                    </CButton>
                </CCol>
            </CRow>
        </CCol>
    </CRow>
  
</template>

<script>
import pdf from 'vue-pdf';
import functions from '@/functions';
const axios = require("axios").default;
import '@/utils'

export default {
    name: 'CadEmpresaDocumento',
    props:{
        form: Object
    },
    components: {pdf},
    data(){
        return {
            srcPdf:'',
            msg:'',
            msgCliente: '',
            showAlert: false,
            showDoc: false,
            showAlertMsgCliente: false,
            items: [],
            fields: [
                {key: 'id'             , label: '#'}, 
                {key: 'status'         , label: 'Status'}, 
                {key: 'createdAt'      , label: 'Criado em'},
                {key: 'expiration_date', label: 'Expira em'},
                {key: 'actions'        , label: 'Ações'}
            ],
            noItemsView:{ 
                noResults: 'Nenhum resultado para este filtro', 
                noItems: 'Nenhum documento enviado!' 
            },
        }
    },
    methods:{
        ...functions,
        viewProcuration(base64){
            this.srcPdf='data:application/pdf;base64,' + base64;
            this.showDoc=true;
        },
        getDocuments(){
            const token = localStorage.getItem('user-token');
            const config = {
                method: 'get',
                url: this.vhost(`agreement/cnpj/${this.form.cnpj.noMask()}`),
                headers: { "Content-Type": "application/json","x-access-token": token},
            };
            axios(config)
                .then(con=>{
                    this.items = con.data.data;
                })
                .catch(this.onError)
        },
        sendDocuments(){
            const token = localStorage.getItem('user-token');
            const data = {
                cnpj: this.form.cnpj.noMask(),
                company_id: this.form.id
            };
            const config = {
                method: 'post',
                url: this.vhost('agreement'),
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token
                },
                data
            };
            axios(config)
                .then(con=>{
                    this.documents = con.data.data;
                    this.getDocuments();
                })
                .catch(this.onError);
        },
        cancelProcuration(id){
            const token = localStorage.getItem('user-token');
            const config = {
                method: 'delete',
                url: this.vhost(`agreement/${id}`),
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token
                },
            };
            axios(config)
                .then(()=>{
                   this.getDocuments();
                })
                .catch(this.onError)
        },
        formatDate(strData){
            const data = new Date(strData);
            const dia  = data.getDate().toString();
            const diaF = (dia.length == 1) ? '0'+dia : dia;
            const mes  = (data.getMonth()+1).toString();
            const mesF = (mes.length == 1) ? '0'+mes : mes;
            const anoF = data.getFullYear();
            const hora = data.getHours().toString();
            const horaF= (hora.length == 1) ? '0'+hora : hora;
            const min  = data.getMinutes().toString();
            const minF = (min.length == 1) ? '0'+min : min;
            return diaF+"/"+mesF+"/"+anoF + ' às ' + horaF + ':' + minF;
        },
        downloadSCR(){
            if(!this.srcPdf){
                return
            }
            const date = new Date();
            const filename =
                "procuracao_" +
                date.getFullYear() +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                ("0" + date.getDate()).slice(-2) +
                ("0" + date.getHours()).slice(-2) +
                ("0" + date.getMinutes()).slice(-2) +
                ("0" + date.getSeconds()).slice(-2) +
                ".pdf";
            var link = document.createElement("a");
            link.download = filename;
            link.href = this.srcPdf;
            document.body.appendChild(link);
            link.click();
        },
        exbMsgCliente(msg){
            this.msgCliente=msg;
            this.showAlertMsgCliente=true;
        },
        onError(err){
            if (err.response && err.response.data) {
                if (err.response.data.auth===false) {
                    localStorage.removeItem('user-token');
                    localStorage.removeItem('user-data');
                    this.$router.push('/admin/login');
                } else if (err.response && err.response.data.erro){
                    this.setAlert(err.response.data.erro); 
                } else {
                    this.setAlert(err.message);
                }       
            } else {
                this.setAlert(err.message);
            }
        },
        setAlert: function (message){
            this.msg=message;
            this.showAlert=(message !== undefined);
        },
    },
    mounted(){
        this.getDocuments();
    }
}
</script>

<style>

</style>