<template>
    <CModal color="primary" size="xl" :show="WizardNewCompany">
      <CModal title="Aviso!" color="danger" :show.sync="showAlert">
        {{ msg }}
        <template #footer>
          <CButton @click="showAlert = false" color="danger">OK</CButton>
        </template>
      </CModal>
      <CTabs variant="pills" vertical :active-tab.sync="activeTab">
        <CTab disabled>
          <template slot="title">
            1. {{tabs[0]}}
          </template>
          <CRow class="form-group-company">
            <CCol sm="12">
              <CInput 
                v-model="form.company_name" 
                class="mb-0" 
                v-uppercase 
                placeholder="Digite a Razão Social da sua empresa." 
                label="Razão Social"
                :is-valid="validator"
              />
            </CCol>
            <CCol sm="12">
              <CInput 
                v-model="form.fantasy_name" 
                class="mb-0" 
                v-uppercase 
                placeholder="Digite a Nome Fantasia da sua empresa." 
                label="Nome Fantasia"
                :is-valid="validator"
              />
            </CCol>
            <CCol sm="12" md="6">
              <CInput 
                v-model="form.cnpj" 
                class="mb-0" 
                placeholder="Digite o CNPJ" 
                label="CNPJ"
                :is-valid="validatorCNPJ"
                v-mask="'##.###.###/####-##'"
              />
            </CCol>
            <CCol sm="12" md="6">
              <CInput 
                v-model="form.cae" 
                class="mb-0" 
                placeholder="Cadastro de Atividade Econômica" 
                label="CAE"
              />
            </CCol>
            <CCol sm="8" lg="10">
              <CInput 
                v-model="form.ie" 
                class="mb-0" 
                placeholder="Digite a Inscrição Estadual" 
                label="Insc. Estadual"
                :is-valid="validator"
                :disabled="chkIE"
              />
            </CCol>
            <CCol sm="4" lg="2">
              <div v-if="!isMobile"><br /><br /></div>
              <CInputCheckbox label="ISENTO" value="chkIE" @update:checked="checkedIE" />
            </CCol>
            <CCol sm="8" lg="10">
              <CInput 
                v-model="form.im" 
                class="mb-0" 
                placeholder="Digite a Inscrição Municipal" 
                label="Insc. Municipal"
                :is-valid="validator"
                :disabled="chkIM"
              />
            </CCol>
            <CCol sm="4" lg="2">
              <div v-if="!isMobile"><br /><br /></div>
              <CInputCheckbox label="ISENTO" value="chkIM" @update:checked="checkedIM" />
            </CCol>
            <CCol sm="12">
              <CInput 
                v-model="form.email" 
                class="mb-0" 
                placeholder="Digite o email corporativo" 
                label="Email"               
                :is-valid="validatorEmail"
                v-lowercase
              />
            </CCol>
            <CCol sm="12" lg="6">
              <CInput 
                v-model="form.phone" 
                class="mb-0"                
                label="Celular"
                v-mask="'(##)#####-####'"
                placeholder="(00)12345-6789"
                :is-valid="validatorPhoneMobile"
              />
            </CCol>            
            <CCol sm="12" lg="6">
              <CInput 
                v-model="form.establishment_date" 
                class="mb-0" 
                label="Data de Fundação"
                type="date"
                :is-valid="validatorDate"
              />
            </CCol>
          </CRow>
        </CTab>
        <CTab disabled>
          <template slot="title">
            2. {{tabs[1]}}
          </template>
          <CRow class="form-group-company">
            <CCol sm="12" lg="3">
              <CInput 
                v-model="form.billing_address.zip_code" 
                class="mb-0" 
                placeholder="Digite o CEP" 
                label="CEP"
                :is-valid="valCompanCEP"
                v-mask="'#####-###'"
              />
            </CCol>
            <CCol sm="12" lg="6">
              <CInput 
                v-model="form.billing_address.address1" 
                class="mb-0" 
                v-uppercase 
                placeholder="Ex.: Rua Alba Valdez" 
                label="Endereço"
                :is-valid="validator"
              />
            </CCol>
            <CCol sm="12" lg="3">
              <CInput 
                v-model="form.billing_address.address_number" 
                class="mb-0"               
                label="Número"
                :is-valid="(nr)=> String(nr).length>0"
              />
            </CCol>
            <CCol sm="12" lg="5">
              <CInput 
                v-model="form.billing_address.address2" 
                class="mb-0" 
                placeholder="Ex.: Sala 701" 
                label="Complemento"
              />
            </CCol>
            <CCol sm="12" lg="7">
              <CInput 
                v-model="form.billing_address.district" 
                class="mb-0" 
                placeholder="Digite o bairro" 
                label="Bairro"
                :is-valid="validator"
                v-uppercase 
              />
            </CCol>
            <CCol sm="12" lg="9">
              <CInput 
                v-model="form.billing_address.city" 
                class="mb-0" 
                placeholder="Digite a cidade" 
                label="Cidade"
                :is-valid="validator"
                v-uppercase 
              />
            </CCol>
            <CCol sm="12" lg="3">
              <CInput 
                v-model="form.billing_address.uf" 
                class="mb-0" 
                label="UF"
                v-uppercase
                :is-valid="validatorUF"
              />
            </CCol>
          </CRow>
        </CTab>
        <CTab disabled>
          <template slot="title">
            3. {{tabs[2]}}
          </template>
          <CRow class="form-group-company">
            <CCol sm="12" md="8">
              <CInput 
                v-model="form.representatives[0].name" 
                class="mb-0" 
                v-uppercase 
                placeholder="Digite o Nome do representante da empresa." 
                label="Nome"
                :is-valid="validator"
              />
            </CCol>
            <CCol sm="12" md="4">
              <CInput 
                v-model="form.representatives[0].tax_id" 
                class="mb-0" 
                placeholder="Digite o CPF" 
                label="CPF"
                :is-valid="validatorCPF"
                v-mask="'###.###.###-##'"
              />
            </CCol>
            <CCol sm="12" md="7">
              <CInput 
                v-model="form.representatives[0].mother" 
                class="mb-0" 
                placeholder="Digite o nome da mãe" 
                label="Nome da Mãe"
                :is-valid="validator"
                v-uppercase 
              />
            </CCol>
            <CCol sm="8" lg="5">
              <CInput 
                v-model="form.representatives[0].birth_date" 
                class="mb-0" 
                placeholder="dd/mm/yyyy" 
                label="Data de Nascimento"
                type="date"
                :is-valid="validatorDate"
              />
            </CCol>
            <CCol sm="12" lg="5">
              <CInput 
                v-model="form.representatives[0].phone" 
                class="mb-0" 
                placeholder="(00)91234-5678" 
                label="Celular"
                :is-valid="validatorPhoneMobile"
                v-mask="'(##)#####-####'"
              />
            </CCol>
            <CCol sm="12" lg="7">
              <CInput 
                v-model="form.representatives[0].email" 
                class="mb-0" 
                placeholder="Digite o email corporativo" 
                label="Email"               
                :is-valid="validatorEmail"
                v-lowercase
              />
            </CCol>
          </CRow>          
        </CTab>
        <CTab disabled>
          <template slot="title">
           4. {{tabs[3]}}
          </template>
          <CRow>
            <CCol sm="12" lg="3">
              <CInput 
                v-model="form.representatives[0].mail_address.zip_code" 
                class="mb-0" 
                placeholder="Digite o CEP" 
                label="CEP"
                :is-valid="valRepresCEP"
                v-mask="'#####-###'"
              />
            </CCol>
            <CCol sm="12" lg="6">
              <CInput 
                v-model="form.representatives[0].mail_address.address1" 
                class="mb-0" 
                v-uppercase 
                placeholder="Ex.: Rua Alba Valdez" 
                label="Endereço"
                :is-valid="validator"
              />
            </CCol>
            <CCol sm="12" lg="3">
              <CInput 
                v-model="form.representatives[0].mail_address.address_number" 
                class="mb-0" 
                v-uppercase 
                label="Número"
                :is-valid="(nr)=> String(nr).length>0"
              />
            </CCol>
            <CCol sm="12" lg="5">
              <CInput 
                v-model="form.representatives[0].mail_address.address2" 
                class="mb-0" 
                placeholder="Ex.: Sala 701" 
                label="Complemento"
              />
            </CCol>
            <CCol sm="12" lg="7">
              <CInput 
                v-model="form.representatives[0].mail_address.district" 
                class="mb-0" 
                placeholder="Digite o bairro" 
                label="Bairro"
                :is-valid="validator"
                v-uppercase 
              />
            </CCol>
            <CCol sm="12" lg="9">
              <CInput 
                v-model="form.representatives[0].mail_address.city" 
                class="mb-0" 
                placeholder="Digite a cidade" 
                label="Cidade"
                :is-valid="validator"
                v-uppercase 
              />
            </CCol>
            <CCol sm="12" lg="3">
              <CInput 
                v-model="form.representatives[0].mail_address.uf" 
                class="mb-0" 
                label="UF"
                :is-valid="validatorUF"
                v-uppercase 
              />
            </CCol>           
          </CRow>
        </CTab>
      </CTabs>
      <template #footer>
        <CButton @click="Closed()" color="danger">Desistir</CButton>
        <CButton :disabled="activeTab===0" @click="activeTab--" color="primary">Anterior</CButton>
        <CButton v-if="activeTab===0" :disabled="valPag1===false" :color="(valPag1===false)? 'light'  : 'primary'" @click="activeTab++">Próximo</CButton>
        <CButton v-else-if="activeTab===1" :disabled="valPag2===false" :color="(valPag2===false)? 'light'  : 'primary'"  @click="activeTab++">Próximo</CButton>
        <CButton v-else-if="activeTab===2" :disabled="valPag3===false" :color="(valPag3===false)? 'light'  : 'primary'" @click="activeTab++">Próximo</CButton>
        <CButton v-else-if="activeTab===3"  disabled color="light">Próximo</CButton>
        <CButton :disabled="!(activeTab===3) || (valPag4===false)" color="success"  @click="postNewCompany">Salvar</CButton>
      </template>
      <template #header>
        <h5>Novo Cadastro</h5>
      </template>
    </CModal>  
</template>

<script>
import functions from "@/functions";
import {base} from "./variables";
const axios = require("axios").default;

export default {
    name: 'WizardCompany',
    data(){
        return {
            activeTab: 0,
            tabs: [
                'Empresa',
                'Endereço',
                'Represetante',
                'End. Representante'
            ],
            form: base,
            chkIE: false,
            chkIM: false,
            isMobile: false,
            showAlert: false,
            msg: '',
        }
    },
    props: {
      WizardNewCompany:Boolean,
      onClose: { type: Function },
      onReload: { type: Function },
    },
    computed:{
      valPag1(){
        const { company_name, fantasy_name, cnpj, ie, im, email, phone, establishment_date } = this.form;
        return (
          this.validator(company_name) &&
          this.validator(fantasy_name) &&
          this.validatorCNPJ(cnpj) &&
          this.validator(ie) &&
          this.validator(im) &&
          this.validatorEmail(email) &&
          this.validatorPhoneMobile(phone) &&
          this.validatorDate(establishment_date)
        )
      },
      valPag2(){
        if (this.form.billing_address){
          const { address1, address_number, district, city, uf, zip_code } = this.form.billing_address;
          const zipcode = this.handlingZipCode(zip_code)
          return (
            this.validator(address1) &&
            (String(address_number).length > 0) &&
            this.validator(district) &&
            this.validator(city) &&
            this.validatorUF(uf) &&
            (String(zipcode).length ===8 || String(zipcode).length ===5) 
          )
        }
        return false
      },
      valPag3(){
        if ( this.form.representatives[0] ){ 
          const { name, mother, tax_id, birth_date, email, phone } = this.form.representatives[0];
          return (
            this.validator(name) &&
            this.validator(mother) &&
            this.validatorCPF(tax_id) &&
            this.validatorEmail(email) &&
            this.validatorPhoneMobile(phone) &&
            this.validatorDate(birth_date)
          )
        }
        return false
      },
      valPag4(){
        if ( this.form.representatives[0] && this.form.representatives[0].mail_address){
          const { address1, address_number, district, city, uf, zip_code } = this.form.representatives[0].mail_address;
          const zipcode = this.handlingZipCode(zip_code);
          return (
            this.validator(address1) &&
            (String(address_number).length > 0) &&
            this.validator(district) &&
            this.validator(city) &&
            this.validatorUF(uf) &&
            (String(zipcode).length ===8 || String(zipcode).length ===5) 
          )
        }
        return false
      }
    },
    methods: {
        ...functions,
        checkedIE: function(checked) {
            this.chkIE= checked;
            if (checked){
                this.form.ie='ISENTO';
            } else {
                this.form.ie='';
            }
        },
        checkedIM: function(checked) {
            this.chkIM= checked;
            if (checked){
                this.form.im='ISENTO';
            } else {
                this.form.im='';
            }
        },
        valCompanCEP (cep) {      
            if (cep && (String(cep).length ===9)){
                (async (cep)=>{
                  try {
                    const con = await this.getAddressZipCode(cep);
                    const {data} = con;
                    this.form.billing_address.address1 = data.logradouro;
                    this.form.billing_address.district = data.bairro;
                    this.form.billing_address.city     = data.localidade;
                    this.form.billing_address.uf       = data.uf;
                    this.form.billing_address.ibge     = data.ibge;
                  } catch  {
                    //CEP nao encontrado
                  }
                })(cep)
              return true
            }
            return false 
        },
        valRepresCEP (cep) {      
            if (cep.length===9){
                (async (cep)=>{
                  try {
                    const con = await this.getAddressZipCode(cep)
                    const {data} = con;
                    this.form.representatives[0].mail_address.address1 = data.logradouro;
                    this.form.representatives[0].mail_address.district = data.bairro;
                    this.form.representatives[0].mail_address.city     = data.localidade;
                    this.form.representatives[0].mail_address.uf       = data.uf;
                    this.form.representatives[0].mail_address.ibge     = data.ibge;
                  } catch {
                    // CEP nao encontrado
                  }
                })(cep)
                return true
            }
            return false
        },
        postNewCompany(){
            const token = localStorage.getItem('user-token');
            const dados = {}
            dados.data = JSON.parse(JSON.stringify( this.form ));
            dados.data.cnpj = this.handlingCNPJ(dados.data.cnpj)
            dados.data.phone = this.handlingPhone(dados.data.phone)
            dados.data.billing_address.zip_code = this.handlingZipCode(dados.data.billing_address.zip_code )
            dados.data.representatives[0].tax_id =  this.handlingCPF(dados.data.representatives[0].tax_id)
            dados.data.representatives[0].phone = this.handlingPhone(dados.data.representatives[0].phone)
            dados.data.representatives[0].mail_address.zip_code = this.handlingZipCode(dados.data.representatives[0].mail_address.zip_code)
            dados.data.representatives[0].mail_address.state = this.UFtoState(dados.data.representatives[0].mail_address.uf)
            dados.data.billing_address.state = this.UFtoState(dados.data.billing_address.uf)      
            axios({
                method: 'post',
                url: this.vhost('company'),
                headers: { "Content-Type": "application/json","x-access-token": token},
                data: dados
            })
            .then(() => {
                this.activeTab=0;          
                this.onReload();
                this.Closed();    
            })
            .catch(err=>{
                if (err.response && err.response.data && err.response.data.auth===false) {
                  localStorage.setItem('user-token', '');
                  this.$router.push('/admin/login');
                } else if (err.response.data && err.response.data.erro){
                  this.setAlert(err.response.data.erro);        
                } else {
                  this.setAlert(err.message);
                }  
            });

        },
        setAlert (message){
          this.msg=message;
          this.showAlert=(message !== undefined);
        },
        Closed(){
          this.onClose(true);
        }
    }
}
</script>

<style>

</style>