<template>
   <CRow>
        <CCol sm="12">
            <DropFile :dataList="ocr" />          
        </CCol>
    </CRow>
</template>

<script>
import DropFile  from '@/Components/DropFile.vue';
import '@/utils'

export default {
    name: 'CadEmpresaDocumento',
    props: {
        ocr: Object
    },
    components: { DropFile  },
}
</script>

<style></style>