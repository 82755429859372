<template>
    <CRow class="form-group-company">
        <CCol sm="12">
            <CInput 
            v-model="form.company_name" 
            class="mb-0" 
            v-uppercase 
            placeholder="Digite a Razão Social da sua empresa." 
            label="Razão Social"
            :is-valid="validator"
            />
        </CCol>
        <CCol sm="12">
            <CInput 
            v-model="form.fantasy_name" 
            class="mb-0" 
            v-uppercase 
            placeholder="Digite a Nome Fantasia da sua empresa." 
            label="Nome Fantasia"
            :is-valid="validator"
            />
        </CCol>
        <CCol sm="12" md="6">
            <CInput 
            v-model="form.cnpj" 
            class="mb-0" 
            placeholder="Digite o CNPJ" 
            label="CNPJ"
            :is-valid="validatorCNPJ"
            v-mask="'##.###.###/####-##'"
            disabled
            />
        </CCol>
        <CCol sm="12" md="6">
            <CInput 
            v-model="form.cae" 
            class="mb-0" 
            placeholder="Cadastro de Atividade Econômica" 
            label="CAE"
            />
        </CCol>
        <CCol sm="8" lg="10">
            <CInput 
            v-model="form.ie" 
            class="mb-0" 
            placeholder="Digite a Inscrição Estadual" 
            label="Insc. Estadual"
            :is-valid="validator"
            :disabled="chkIE"
            />
        </CCol>
        <CCol sm="4" lg="2">
            <div v-if="!isMobile"><br /><br /></div>
            <CInputCheckbox label="ISENTO" :checked="chkIE" @update:checked="checkedIE" />
        </CCol>
        <CCol sm="8" lg="10">
            <CInput 
            v-model="form.im" 
            class="mb-0" 
            placeholder="Digite a Inscrição Municipal" 
            label="Insc. Municipal"
            :is-valid="validator"
            :disabled="chkIM"
            />
        </CCol>
        <CCol sm="4" lg="2">
            <div v-if="!isMobile"><br /><br /></div>
            <CInputCheckbox label="ISENTO" :checked="chkIM"  @update:checked="checkedIM" />
        </CCol>
    </CRow>
</template>

<script>

import functions from "@/functions";

export default {
    name:'CadEmpresaBasico',
    props:{
        form:{
            type: Object,
            required: true
        },
        chkIE:{
            type: Boolean,
            required: true
        },
        chkIM:{
            type: Boolean,
            required: true
        }
    },
    data(){
        return {
            isMobile: false,
        }
    },
    methods:{
        ...functions,
        onResize () {
            this.isMobile = window.innerWidth < 576;
            this.isFullDesktop = window.innerWidth > 1300;
        },
        checkedIE: function(checked) {
            this.chkIE= checked;
            if (checked){
                this.form.ie='ISENTO';
            } else {
                this.form.ie='';
            }
        },
        checkedIM: function(checked) {
            this.chkIM= checked;
            if (checked){
                this.form.im='ISENTO';
            } else {
                this.form.im='';
            }
        },
    },
    mounted () {
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
    },

}
</script>

<style>

</style>