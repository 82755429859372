<template>
    <CRow class="form-group-company">
        <CModal v-if="!!showCadUser" size="lg" title="Usuário" color="primary" :show.sync="showCadUser">
            <CRow class="mb-3 mt-0">
                <CCol sm="12" md="8">
                    <CInput
                        v-uppercase
                        v-model="newUser.name" 
                        class="mb-0" 
                        placeholder="Nome" 
                        label="None"
                        :is-valid="String(newUser.name).length > 3"
                    />
                </CCol>
                <CCol sm="12" md="4">
                    <CInput 
                        v-model="newUser.login"
                        class="mb-0"
                        placeholder="Login"
                        label="Login"
                        :is-valid="String(newUser.login).length > 3"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12" md="9">
                    <CInput
                        v-model="newUser.email" 
                        class="mb-0" 
                        placeholder="Email" 
                        label="Email"
                        :is-valid="validatorEmail"
                        :readonly="newUser.isEdit"
                    />
                </CCol>
                <CCol sm="12" md="3" class="mt-4">
                    <CInputCheckbox
                      label="Admin"
                      :value="newUser.isAdmin"
                      :checked="newUser.isAdmin===true"
                      @update:checked="(e)=>newUser.isAdmin=e"
                    />
                </CCol>
            </CRow>
            <template #footer>
                <CButton v-if="newUser.id===-1" @click="saveNewUser" color="primary">Salvar</CButton>
                <CButton v-else @click="saveEditUser" color="primary">Salvar</CButton>
                <CButton @click="showCadUser = false" color="danger">Desistir</CButton>
            </template>
        </CModal>
        <CModal 
            v-if="!!showResetUserConfirmation"
            title="Confirmação de Redefinição de Senha" 
            color="danger" 
            :show.sync="showResetUserConfirmation">
            <CRow class="mb-0 mt-0">
                <CCol sm="12">
                    <span>
                        <li>                       
                        Deseja resetar a senha do login 
                        <CBadge color="light"><h6>{{newUser.login}}</h6></CBadge> 
                        e reenviar o email para 
                        <CBadge color="light"><h6>{{newUser.email}}</h6></CBadge>?
                        </li>                      
                   </span>
                </CCol>
            </CRow>
            <template #footer>
                <CButton @click="resetUser" color="primary">Resetar e reenviar e-mail</CButton>
                <CButton @click="showResetUserConfirmation = false" color="danger">Desistir</CButton>
            </template>
        </CModal>
        <CCol sm="12" class="px-4 mt-3" >
            <CRow class="mb-3 mt-0">
                <CCol sm="6" md="2">
                    <CButton block color="success" @click="addUser">
                        <CIcon name="cil-chevron-bottom" />
                        Novo
                    </CButton>
                </CCol>
            </CRow>
            <CRow >
                <CCol sm="12">
                    <CDataTable
                        hover
                        striped
                        border                        
                        fixed                        
                        :items="items"
                        :fields="fields"
                        :items-per-page="5"
                        :noItemsView="noItemsView"
                        pagination
                    >
                    <template #active="{item}">
                        <td>
                            <h5>
                            <CBadge v-if="item.active===true" :color="getBadge(item.active)">Ativo</CBadge>
                            <CBadge v-else-if="item.active===false" :color="getBadge(item.active)">Inativo</CBadge>
                            </h5>
                        </td>
                    </template>
                    <template #actions="{item}">
                        <td>  
                            <CButton
                                :name=item.id
                                size="sm"
                                :key="'ed'+item.id"
                                color="info"
                                v-if="item.active===true"
                                @click="editUser(item)"
                            >
                                <CIcon size="sm" name="cil-pencil"/>
                            </CButton>
                            &nbsp;
                            <CButton
                                :name=item.id
                                size="sm"
                                :key="'ex'+item.id"
                                color="danger"
                                v-if="item.active===true"
                                @click="cancelUser(item.id)"
                            >
                                <CIcon size="sm" name="cil-trash"/>
                            </CButton>
                            &nbsp;
                            <CButton
                                :name=item.id
                                size="sm"
                                :key="'re'+item.id"
                                color="success"
                                v-if="item.email && item.active===true"
                                @click="resetUserConfirmation(item)"
                            >
                               <font-awesome-icon icon="recycle" class="c-icon c-icon-sm"/>
                            </CButton>
                            
                            <span v-if="item.active===false">
                                Nenhum Ação
                            </span>
                        </td>
                    </template>
                    </CDataTable>
                </CCol>
            </CRow>
        </CCol>
    </CRow>  
</template>

<script>
import functions from '@/functions';
const axios = require("axios").default;

export default {
    name:'CadEmpresaUsuarios',
    props:{
        form:{
            type: Object,
            required: true,
        },
        onError:{
            type: Function,
            required: true,
        }
    },
    data(){
        return {
            items: [],
            fields: [
                {key: 'id'     , label: '#'}, 
                {key: 'name'   , label: 'Nome'}, 
                {key: 'login'  , label: 'Login'},
                {key: 'active' , label: 'Situação'},
                {key: 'actions', label: 'Ações'}
            ],
            noItemsView:{ 
                noResults: 'Nenhum resultado para este filtro', 
                noItems: 'Nenhum usuário cadastrado!' 
            },
            showCadUser: false,
            showResetUserConfirmation: false,
            newUser:{
                id: -1,
                name:'',
                login:'',
                email: '',
                isAdmin: false,
                company_id: 0,
                isEdit: false,
            },
        }
    },
    methods:{
        ...functions,
        getUsers(){
            const token = localStorage.getItem('user-token');
            const config = {
                method: 'get',
                url: this.vhost(`transaction/user/${this.form.id}`),
                headers: { "Content-Type": "application/json","x-access-token": token},
            };
            axios(config)
                .then(con=>{
                    this.items = con.data.data;
                })
                .catch(this.onError)
        },
        addUser(){
            this.newUser.id     = -1;
            this.newUser.name   = '';
            this.newUser.login  = '';
            this.newUser.email  = '';
            this.newUser.isAdmin= false;
            this.newUser.isEdit = false;
            this.showCadUser=true;
        },
        editUser(user=Object){
            this.newUser.id     = user.id;
            this.newUser.name   = user.name;
            this.newUser.login  = user.login;
            this.newUser.email  = user.email;
            this.newUser.isAdmin= user.admin;
            this.newUser.isEdit = true;
            this.showCadUser=true;
        },
        cancelUser(id){
            const token = localStorage.getItem('user-token');
            const config = {
                method: 'put',
                url: this.vhost(`transaction/user/${id}`),
                headers: { "Content-Type": "application/json","x-access-token": token},
                data: {active:false},
            };
            axios(config)
                .then(()=>{
                    this.getUsers();
                })
                .catch(this.onError);                
        },
        saveNewUser(){
            const token = localStorage.getItem('user-token');
            this.newUser.company_id=this.form.id
            const config = {
                method: 'post',
                url: this.vhost('transaction/user/'),
                headers: { "Content-Type": "application/json","x-access-token": token},
                data: this.newUser,
            };
            axios(config)
                .then(()=>{
                    this.showCadUser=false;
                    this.getUsers();
                })
                .catch(this.onError);
        },
        saveEditUser(){
            const token = localStorage.getItem('user-token');
            const data= {
                name:this.newUser.name,
                login:this.newUser.login,
                email:this.newUser.email,
                admin:this.newUser.isAdmin,
            }
            const config = {
                method: 'put',
                url: this.vhost(`transaction/user/${this.newUser.id}`),
                headers: { "Content-Type": "application/json","x-access-token": token},
                data,
            };
            axios(config)
                .then(()=>{
                    this.showCadUser=false;
                    this.getUsers();
                })
                .catch(this.onError);
        },
        resetUserConfirmation(item){
            this.newUser.id     = item.id;
            this.newUser.name   = item.name;
            this.newUser.login  = item.login;
            this.newUser.email  = item.email;
            this.newUser.isAdmin= item.admin;
            this.newUser.isEdit = false;
            this.showResetUserConfirmation=true;
        },
        resetUser(){
            const token = localStorage.getItem('user-token');
            const config = {
                method: 'put',
                url: this.vhost(`transaction/user/${this.newUser.id}/resetpassword`),
                headers: { "Content-Type": "application/json","x-access-token": token},
            };
            this.$loading(true);
            axios(config)
                .then(()=>{
                    this.$loading(false);
                    this.showResetUserConfirmation=false;
                    this.getUsers();
                })
                .catch(this.onError);
        },
        getBadge (status) {
            switch (status) {
                case true: return 'success'
                case false: return 'secondary'
                default: 'primary'
            }
        }
    },
    mounted(){
        this.getUsers();
    },

}
</script>

<style>

</style>