const base={
    company_name:'',
    fantasy_name:'',
    cnpj:'',
    ie:'',
    im:'',
    cae:'',
    establishment_date:'',        
    email:'',
    phone:'',
    note:null,
    billing_address:{
      address1:'',
      address2:'',
      address_number:null,
      district:'',
      city:'',
      state:'',
      zip_code:''
    },
    representatives:[{
        name:'',
        mother:'',
        tax_id:'',
        birth_date:'',
        email:'',
        phone:'',
        mail_address:{
            address1:'',
            address2:'',
            address_number:'',
            district:'',
            city:'',
            state:'',
            zip_code:''
        }
  }],
  ocr: {
    self: '',
    name_self: '',
    cnh: '',
    name_cnh: '',
    identity_front: '',
    name_identity_front: '',
    identity_back: '',
    name_identity_back: '',
  },
};

function form() {
  this.company_name='';
  this.fantasy_name='';
  this.cnpj='';
  this.ie='';
  this.im='';
  this.cae='';
  this.establishment_date='';        
  this.email='';
  this.phone='';
  this.note='';
  this.billing_address={
    address1:'',
    address2:'',
    address_number:'',
    district:'',
    city:'',
    state:'',
    zip_code:'',
  }
  this.representatives=[];
  this.representatives.push({
    name: '',
    mother: '',
    tax_id: '',
    birth_date: '',
    email: '',
    phone: '',
    mail_address: {
      address1: '',
      address2: '',
      address_number: '',
      district: '',
      city: '',
      state: '',
      zip_code: '',
    }
  });
  this.ocr = {
    self: '',
    name_self: '',
    cnh: '',
    name_cnh: '',
    identity_front: '',
    name_identity_front: '',
    identity_back: '',
    name_identity_back: '',
  };
}

module.exports.base=base;
module.exports.formDefault=form;