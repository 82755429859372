var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"form-group-company"},[_c('CModal',{attrs:{"title":"Aviso!","color":"danger","show":_vm.showAlert},on:{"update:show":function($event){_vm.showAlert=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.showAlert = false}}},[_vm._v("OK")])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.msg)+" ")]),_c('CModal',{attrs:{"title":"Aviso!","color":"danger","show":_vm.showAlertMsgCliente},on:{"update:show":function($event){_vm.showAlertMsgCliente=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.showAlertMsgCliente = false}}},[_vm._v("OK")])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.msgCliente)+" ")]),_c('CModal',{attrs:{"size":"lg","title":"Documento","color":"primary","show":_vm.showDoc},on:{"update:show":function($event){_vm.showDoc=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showDoc = false}}},[_vm._v("Sair")]),_c('CButton',{attrs:{"color":"info"},on:{"click":_vm.downloadSCR}},[_vm._v("Baixar")])]},proxy:true}])},[_c('pdf',{attrs:{"src":_vm.srcPdf}})],1),_c('CCol',{staticClass:"px-4 mt-3",attrs:{"sm":"12"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CDataTable',{attrs:{"hover":"","striped":"","border":"","fixed":"","items":_vm.items,"fields":_vm.fields,"items-per-page":5,"noItemsView":_vm.noItemsView,"pagination":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',[_vm._v(" "+_vm._s(!item.active || !item.token_active ? 'Inativo' : item.procuration ? 'Assinado' : 'Não assinado')+" ")])])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")])])]}},{key:"expiration_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',[_vm._v(" "+_vm._s(_vm.formatDate(item.expiration_date))+" ")])])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.active && item.token_active && item.procuration)?_c('CButton',{key:'vw'+item.id,attrs:{"name":item.id,"size":"sm","color":"success"},on:{"click":function($event){return _vm.viewProcuration(item.procuration)}}},[_c('CIcon',{attrs:{"size":"sm","name":"cil-magnifying-glass"}})],1):_vm._e(),_vm._v("   "),(item.active===true)?_c('CButton',{key:'ex'+item.id,attrs:{"name":item.id,"size":"sm","color":"danger"},on:{"click":function($event){return _vm.cancelProcuration(item.id)}}},[_c('CIcon',{attrs:{"size":"sm","name":"cil-ban"}})],1):_vm._e(),_vm._v("   "),(item.error_msg)?_c('CButton',{key:'msg'+item.id,attrs:{"name":item.id,"size":"sm","color":"info"},on:{"click":function($event){return _vm.exbMsgCliente(item.error_msg)}}},[_c('CIcon',{attrs:{"size":"sm","name":"cil-bell"}})],1):_vm._e()],1)]}}])})],1)],1),_c('CRow',{staticClass:"align-items-center"},[_c('CCol',{attrs:{"sm":"12","md":"6"}},[_c('CButton',{attrs:{"block":"","color":"dark"},on:{"click":_vm.sendDocuments}},[_vm._v(" Enviar Procuração "),_c('CIcon',{attrs:{"name":"cil-find"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }