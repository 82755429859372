<template>
    <CRow class="form-group-company">
        <CCol sm="12" md="8">
            <CInput 
                v-model="form.representatives[0].name" 
                class="mb-0" 
                v-uppercase 
                placeholder="Digite o Nome do representante da empresa." 
                label="Nome"
                :is-valid="validator"
            />
        </CCol>
        <CCol sm="12" md="4">
            <CInput 
                v-model="form.representatives[0].tax_id" 
                class="mb-0" 
                placeholder="Digite o CPF" 
                label="CPF"
                :is-valid="validatorCPF"
                v-mask="'###.###.###-##'"
            />
        </CCol>
        <CCol sm="12" md="7">
            <CInput 
                v-model="form.representatives[0].mother" 
                class="mb-0" 
                placeholder="Digite o nome da mãe" 
                label="Nome da Mãe"
                :is-valid="validator"
                v-uppercase 
            />
        </CCol>
        <CCol sm="8" lg="5">
            <CInput 
                v-model="form.representatives[0].birth_date" 
                class="mb-0" 
                placeholder="dd/mm/yyyy" 
                label="Data de Nascimento"
                type="date"
                :is-valid="validatorDate"
            />
        </CCol>
        <CCol sm="12" lg="5">
            <CInput 
                v-model="form.representatives[0].phone" 
                class="mb-0" 
                placeholder="(11)91234-5678" 
                label="Celular"
                :is-valid="validatorPhoneMobile"
                v-mask="'(##)#####-####'"
            />
        </CCol>
        <CCol sm="12" lg="7">
            <CInput 
                v-model="form.representatives[0].email" 
                class="mb-0" 
                placeholder="Digite o email corporativo" 
                label="Email"
                :is-valid="validatorEmail"
                v-lowercase
            />
        </CCol>
    </CRow>
</template>

<script>
import functions from "@/functions";
export default {
    name:'CadEmpresaResponsavel',
    props:{
        form:{
            type: Object,
            requered: true
        }
    },
    methods:{
        ...functions,
    }

}
</script>

<style>

</style>