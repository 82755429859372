<template>
    <CRow>
        <CCol sm="12" >
            <CTabs>
                <CTab>
                    <template slot="title">
                    Registro
                    </template>
                    <CRow class="mb-3 mt-3">
                        <CCol v-if="(bankAccount && bankAccount.length===0)" sm="6" md="2">
                            <CButton block color="success" @click="createAccount(form.cnpj)">
                                <CIcon name="cil-chart-pie" />
                                Criar
                            </CButton>
                        </CCol>
                        <CCol v-else  sm="6" md="2" >
                            <CButton block color="primary" @click="updateAccount(form.cnpj)">
                                <CIcon name="cil-chart-pie"/>
                                Atualizar
                            </CButton>
                        </CCol>
                        <CCol v-if="bankAccount && bankAccount.length && bankAccount[0].status==='REJECTED'" sm="6" md="2" >
                                <CButton block color="info" @click="reSendAccount(form.cnpj)">
                                    <CIcon name="cil-chart-pie"/>
                                    Reenviar
                                </CButton>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="12">
                            <CDataTable
                                :hover="false"
                                :striped="false"
                                :border="false"
                                :small="false"
                                :fixed="false"
                                :items="bankAccount || []"
                                :fields="fieldsBankAccount"
                                :items-per-page="5"
                                :dark="false"
                                :noItemsView="noBankAccount"
                                pagination>
                                <template #status="{item}">
                                <td>
                                    <CBadge v-if="item.status==='Não Sincronizado'" color="danger">Não Sincronizado</CBadge>
                                    <CBadge v-else-if="item.status==='REGULAR'" color="success">REGULAR</CBadge>
                                    <CBadge v-else color="warning">{{item.status}}</CBadge>
                                </td>
                                </template>
                            </CDataTable>
                        </CCol>
                    </CRow>
                </CTab>
                <CTab>
                    <template slot="title">
                    Chave PIX
                    </template>
                    <CRow class="mb-3 mt-3">
                        <CCol sm="6" md="2">
                            <CButton block color="success" @click="newKeyPix(form.cnpj)">
                                <CIcon name="cil-chart-pie"/>
                                Incluir
                            </CButton>
                        </CCol>
                        <CCol sm="6" md="2" >
                            <CButton block color="info" @click="upKeysPIX(form.cnpj)">
                                <CIcon name="cil-chart-pie"/>
                                Atualizar
                            </CButton>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="12"  v-for="(keyPix, index) in keysPix" v-bind:key="keyPix.id">
                        <CRow>
                            <CCol sm="9" lg="11">
                                <CInput 
                                    :value="keyPix.name" 
                                    class="mb-0" 
                                    placeholder="" 
                                    :label="`Chave PIX ${index+1}`"
                                    :is-valid="keyPix.status=== 'ACTIVE'"
                                    :key="keyPix.id"
                                    readonly
                                    delKeyPIX
                                />
                            </CCol>
                            <CCol sm="3" lg="1">
                                <div v-if="!isMobile"><br /></div>
                                <CButton 
                                    size="sm" 
                                    color="info"
                                    class="mt-2"
                                    @click="delKeyPIX(form.cnpj,keyPix.name)" 
                                > 
                                    <CIcon name="cil-trash"/>                                
                                </CButton>
                            </CCol>
                        </CRow>
                        
                    </CCol>
                    </CRow>
                </CTab>
            </CTabs>
        </CCol>
    </CRow>
</template>

<script>
import functions from '@/functions';

export default {
    name:'CadEmpresaContas',
    props:{
        form:{
            type: Object,
            required: true,
        },
        bankAccount:{
            type: Array,
            required: true,
        },
        keysPix:{
            type: Array,
            required: true,
        },
        upKeysPIX:{
            type: Function,
            required: true,
        },
        newKeyPix:{
            type: Function,
            required: true,
        },
        createAccount:{
            type: Function,
            required: true,
        },
        updateAccount:{
            type: Function,
            required: true,
        },
        reSendAccount:{
            type: Function,
            required: true,
        },
    },
    data(){
        return {
            isMobile: false,
            isFullDesktop: false,
            fieldsBankAccount:[
                {key: 'account_id'   , label: 'Conta'},
                {key: 'account_holder_id' , label: 'Titular'},
                {key: 'status' , label: 'Status'},
            ],
            noBankAccount:{ 
                noResults: 'Nenhum resultado para este filtro', 
                noItems: 'Nenhuma conta cadastrada' 
            },
        }
    },
    methods:{
        ...functions,
        onResize () {
            this.isMobile = window.innerWidth < 576;
            this.isFullDesktop = window.innerWidth > 1300;
        },
    },
    mounted () {
      this.onResize();
      window.addEventListener('resize', this.onResize, { passive: true });
    },
}
</script>

<style>

</style>