<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CInput 
                v-model="form.email" 
                class="mb-0" 
                placeholder="Digite o email corporativo" 
                label="Email"
                :is-valid="validatorEmail"
                v-lowercase
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="6">
                <CInput 
                v-model="form.phone" 
                class="mb-0"
                label="Celular"
                v-mask="'(##)#####-####'"
                placeholder="(00)91234-5678"
                :is-valid="validatorPhoneMobile"
                />
            </CCol>            
            <CCol sm="12" lg="6">
                <CInput 
                v-model="form.establishment_date" 
                class="mb-0" 
                label="Data de Fundação"
                type="date"
                :is-valid="validatorDate"
                />
            </CCol>
        </CRow>
    </div>
</template>

<script>
import functions from "@/functions"
export default {
    name: 'CadEmpresaOutInformacoes',
    props:{
        form:{
            type: Object,
            required: true
        },
    },
    methods:{
        ...functions,
        onResize () {
            this.isMobile = window.innerWidth < 576;
            this.isFullDesktop = window.innerWidth > 1300;
        },
    },
    mounted () {
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
    },   

}
</script>

<style>

</style>