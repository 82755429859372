<template>
    <div>
        <CModal title="Aviso!" color="danger" :show.sync="showAlert">
          {{ msg }}
          <template #footer>
                <CButton @click="showAlert = false" color="danger">OK</CButton>
          </template>
        </CModal>
        <CModal size="lg" title="Imagem" color="primary" :show.sync="showImagem">
            <img class="preview-img-modal" :src="imageModal" />
            <template #footer>
                <CButton @click="showImagem = false" color="primary">OK</CButton>
            </template>
        </CModal>

        <div class="main">
            <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <input type="file" multiple name="file" id="fileInput" class="hidden-input" @change="onChange" ref="file"
                    accept=".jpg,.jpeg" />

                <label for="fileInput" class="file-label">
                    <div v-if="isDragging">Solte os arquivos aqui.</div>
                    <div v-else>Solte arquivos aqui ou <u>clique aqui</u> para fazer o upload.</div>
                </label>

                <!-- Note: Only add the code block below -->
                <div class="preview-container mt-4" v-if="files.length">
                    <div v-for="file in files" :key="file.name" class="preview-card">
                        <CRow>
                            <CCol lg="12">
                                <img class="preview-img" :src="generateURL(file)" />
                                <p>
                                {{ file.name }} -
                                {{ Math.round(file.size / 1000) + "kb" }}
                                </p>

                            </CCol>
                        </CRow>
                        
                        <CRow>
                            <CCol lg="12">
                                <CButton
                                    size="sm"
                                    @click="remove(files.indexOf(file))"                            
                                >
                                    <CIcon size="sm" name="cil-trash"/>
                                </CButton>
                                <br />
                                <CButton
                                    size="sm"
                                    @click="open(file)"
                                >
                                    <CIcon size="sm" name="cil-image"/>
                                </CButton>
                            </CCol>
                        </CRow>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropFile',
    props: {
        dataList: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isDragging: false,
            files: [],
            fileNameAccepted: [
                  'self.jpg'
                , 'cnh.jpg'
                , 'rg_frente.jpg'
                , 'rg_verso.jpg'
                , 'self.jpeg'
                , 'cnh.jpeg'
                , 'rg_frente.jpeg'
                , 'rg_verso.jpeg'
            ],
            showAlert: false,
            showImagem: false,
            imageModal: '',
            msg: '',
        };
    },
    methods: {
        onChange() {
            let files = [...this.$refs.file.files];
            files.map(async (e) => {
               // e.preview = URL.createObjectURL(e);
                if (!this.files.find(f => f.name.split('.')[0] === e.name.split('.')[0])) {
                    if (!this.fileNameAccepted.includes(e.name)) {
                        this.setAlert('Nome do arquivo não reconhecido: ' + e.name);
                    }
                    else if (e.size > 204800) {
                        this.setAlert('Arquivo muito grande: ' + e.name + '. Tamanho máximo: 200kb por arquivo');
                    }
                    else if (e.type !== 'image/jpeg' && e.type !== 'image/jpg') {
                        this.setAlert('Tipo de arquivo não permitido: ' + e.name);
                    } else {                        
                        

                        if (e.name.split('.')[0] === 'self') {
                            this.dataList.self = await this.getBase64Image(e);
                            this.dataList.name_self = e.name;
                            this.files.push(this.newWebApiFile(e.name, this.dataList.self));
                        } else if (e.name.split('.')[0] === 'cnh') {
                            this.dataList.cnh = await this.getBase64Image(e);
                            this.dataList.name_cnh = e.name;
                            this.files.push(this.newWebApiFile(e.name, this.dataList.cnh));
                        } else if (e.name.split('.')[0] === 'rg_frente') {
                            this.dataList.identity_front = await this.getBase64Image(e);
                            this.dataList.name_identity_front = e.name;
                            this.files.push(this.newWebApiFile(e.name, this.dataList.identity_front));
                        } else if (e.name.split('.')[0] === 'rg_verso') {
                            this.dataList.identity_back = await this.getBase64Image(e);
                            this.dataList.name_identity_back = e.name;
                            this.files.push(this.newWebApiFile(e.name, this.dataList.identity_back));
                        }
                    }
                } else {
                    this.setAlert('Arquivo já adicionado.');
                }
            });

        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },
        open(file) {
            this.imageModal = this.generateURL(file);
            this.showImagem = true;
        },
        remove(i) {
            // remove from this.dataList
            if (this.files[i].name.split('.')[0] === 'self') {
                this.dataList.self = '';
                this.dataList.name_self = '';
            } else if (this.files[i].name.split('.')[0] === 'cnh') {
                this.dataList.cnh = '';
                this.dataList.name_cnh = '';
            } else if (this.files[i].name.split('.')[0] === 'rg_frente') {
                this.dataList.identity_front = '';
                this.dataList.name_identity_front = '';
            } else if (this.files[i].name.split('.')[0] === 'rg_verso') {
                this.dataList.identity_back = '';
                this.dataList.name_identity_back = '';
            }
            this.files.splice(i, 1);
        },
        generateURL(file) {
            if (file.name.split('.')[0] === 'self' && this.dataList.self !== '') {
                console.log('selfOK');
                return `data:${file.type};base64,${this.dataList.self}`;
            }
            if (file.name.split('.')[0] === 'cnh' && this.dataList.cnh !== '') {
                console.log('cnhOK');
                return `data:${file.type};base64,${this.dataList.cnh}`;
            }
            if (file.name.split('.')[0] === 'rg_frente' && this.dataList.identity_front !== '') {
                return `data:${file.type};base64,${this.dataList.identity_front}`;
            }
            if (file.name.split('.')[0] === 'rg_verso' && this.dataList.identity_back !== '') {
                return `data:${file.type};base64,${this.dataList.identity_back}`;
            }
        },
        async getBase64Image(url) {
            const reader = new FileReader();
            await new Promise((resolve, reject) => {
                reader.onload = resolve;
                reader.onerror = reject;
                reader.readAsDataURL(url);
            });
            return await reader.result.split(',')[1];
        },
        setAlert: function (message) {
            this.msg = message;
            this.showAlert = (message !== undefined);
        },
        onError(err) {
            this.$loading(false);
            if (err.response && err.response.data) {
                if (err.response.data.auth === false) {
                    localStorage.removeItem('user-token');
                    localStorage.removeItem('user-data');
                    this.$router.push('/admin/login');
                } else if (err.response && err.response.data.erro) {
                    this.setAlert(err.response.data.erro);
                } else {
                    this.setAlert(err.message);
                }
            } else if (typeof (err) === 'string') {
                this.setAlert(err);
            } else {
                this.setAlert(err.message);
            }
        },
        newWebApiFile(name, base) {
            let type = name.split('.')[1] === 'jpg' ? 'image/jpg' : 'image/jpeg';
            return new File(
                [base]
                , name
                , { type }
            )
        },
        
    },
    mounted() {
        if (this.dataList.name_self !== '') {            
            this.files.push(
                this.newWebApiFile(this.dataList.name_self, this.dataList.self)
            );
        }
        if (this.dataList.name_cnh !== '') {
            this.files.push(
                this.newWebApiFile(this.dataList.name_cnh, this.dataList.cnh)
            );
        }
        if (this.dataList.name_identity_front !== '') {
            this.files.push(
                this.newWebApiFile(this.dataList.name_identity_front, this.dataList.identity_front)
            );
        }
        if (this.dataList.name_identity_back !== '') {
            this.files.push(
                this.newWebApiFile(this.dataList.name_identity_back, this.dataList.identity_back)
            );
        }     
    },
};
</script>
<style scoped src="@/assets/dropfile.css"></style>