<template>
    <CRow class="form-group-company">
        <CCol sm="4">
            <CInput 
            v-model="form.tax" 
            class="mb-0" 
            v-uppercase
            label="Taxa Administrativa"
            v-mask="'0.##'"
            />
        </CCol>
    </CRow>
</template>

<script>

import functions from "@/functions";

export default {
    name:'CadEmpresaTaxa',
    props:{
        form:{
            type: Object,
            required: true
        }
    },
    data(){
        return {
            isMobile: false,
        }
    },
    methods:{
        ...functions,
        onResize () {
            this.isMobile = window.innerWidth < 576;
            this.isFullDesktop = window.innerWidth > 1300;
        },
    },
    mounted () {
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
    },

}
</script>

<style>

</style>