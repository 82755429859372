<template>
    <CRow class="form-group-company">
        <CCol sm="12" lg="3">
            <CInput 
            v-model="form.billing_address.zip_code" 
            class="mb-0" 
            placeholder="Digite o CEP" 
            label="CEP"
            :is-valid="valCompanCEP"
            v-mask="'#####-###'"
            />
        </CCol>
        <CCol sm="12" lg="6">
            <CInput 
            v-model="form.billing_address.address1" 
            class="mb-0" 
            v-uppercase 
            placeholder="Ex.: Rua Alba Valdez" 
            label="Endereço"
            :is-valid="validator"
            />
        </CCol>
        <CCol sm="12" lg="3">
            <CInput 
            v-model="form.billing_address.address_number" 
            class="mb-0"
            label="Número"
            />
        </CCol>
        <CCol sm="12" lg="5">
            <CInput 
            v-model="form.billing_address.address2" 
            class="mb-0" 
            placeholder="Ex.: Sala 701" 
            label="Complemento"
            />
        </CCol>
        <CCol sm="12" lg="7">
            <CInput 
            v-model="form.billing_address.district" 
            class="mb-0" 
            placeholder="Digite o bairro" 
            label="Bairro"
            :is-valid="validator"
            v-uppercase 
            />
        </CCol>
        <CCol sm="12" lg="9">
            <CInput 
            v-model="form.billing_address.city" 
            class="mb-0" 
            placeholder="Digite a cidade" 
            label="Cidade"
            :is-valid="validator"
            v-uppercase 
            />
        </CCol>
        <CCol sm="12" lg="3">
            <CInput 
            v-model="form.billing_address.uf" 
            class="mb-0" 
            label="UF"
            v-uppercase
            :is-valid="validatorUF"
            />
        </CCol>
    </CRow>
  
</template>

<script>
import functions from '@/functions';
const axios = require('axios');
export default {
    name:'CadEmpresaEndereco',
    props:{
        form:{
            type: Object,
            required: true
        },
        readOnly:{
            type:Boolean,
            required: true, 
        }
    },
    methods:{
        ...functions,
        valCompanCEP (cep) {      
            if (cep.length===9){
                if (this.readOnly){
                    return true;
                }
                axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                    .then(res=>{
                        this.form.billing_address.address1 = res.data.logradouro;
                        this.form.billing_address.district = String(res.data.bairro).toUpperCase();
                        this.form.billing_address.city     = String(res.data.localidade).toUpperCase();
                        this.form.billing_address.uf       = String(res.data.uf).toUpperCase();
                        this.form.billing_address.ibge     = res.data.ibge;
                    })
                
                return true
            }
            return false 
        },
    },
}
</script>

<style>

</style>