<template>
  <div>
    <CRow v-if="!viewFieldMode">
      <CCol lg="12">
        <transition name="fade">
          <CCard>
            <CCardHeader>
              <CIcon name="cil-building" /> Empresas
              <div class="card-header-actions">
                <CLink class="card-header-action btn-minimize" @click="formCollapsed=!formCollapsed">
                  <CIcon :name="`cil-chevron-${formCollapsed ? 'bottom' : 'top'}`"/>
                </CLink>
                <CLink href="#" class="card-header-action btn-close" @click="Fechar">
                  <CIcon name="cil-x-circle"/>
                </CLink>
              </div>
            </CCardHeader>
            <CCollapse :show="formCollapsed">
              <CCardBody>
                <CRow class="align-items-center">
                  <CCol  sm="3" md="2" class="mb-3 mb-xl-0">
                    <CButton block color="dark" shape="pill" @click="newDocument">Novo</CButton>
                  </CCol>
                </CRow>
                <CRow class="align-items-center mt-3">
                  <CCol  sm="12" class="mb-3 mb-xl-0">
                    <CDataTable
                        :hover="hover"
                        :striped="striped"
                        :border="border"
                        :small="small"
                        :fixed="fixed"
                        :items="items"
                        :fields="fields"
                        :items-per-page="small ? 10 : 5"
                        :dark="dark"
                        :noItemsView="noItemsView"
                        pagination
                    >
                        <template #active="{item}">
                            <td>
                                <CBadge v-if="item.active===true" :color="getBadge(item.active)">Ativo</CBadge>
                                <CBadge v-else-if="item.active===false" :color="getBadge(item.active)">Inativo</CBadge>
                            </td>
                        </template>
                        <template #cnpj="{item}">
                          <td> {{formatCNPJ(item.cnpj)}}</td>
                        </template>
                        <template #actions="{item}">
                            <td>                                
                                <CButton
                                  :name=item.id
                                  size="sm"
                                  :key="'ed'+item.id"
                                  color="info"
                                  @click="editCompany(item.cnpj)"
                                >
                                  <CIcon size="sm" name="cil-pencil"/>
                                </CButton>
                                &nbsp;
                                <CButton
                                  :name=item.id
                                  size="sm"
                                  :key="'ex'+item.id"
                                  color="danger"
                                  v-if="item.active===true"
                                  @click="deleteCompany(item.cnpj)"
                                >
                                  <CIcon size="sm" name="cil-trash"/>
                                </CButton>
                            </td>
                        </template>
                        <template #fantasy_name="{item}" v-if="isFullDesktop">
                            <td v-if="item.fantasy_name.length > 30">
                              {{item.fantasy_name.substring(0,30 )}}
                              <a v-c-tooltip.hover.click="item.fantasy_name">
                                ...
                              </a>
                            </td>
                            <td v-else>
                              {{item.fantasy_name}}
                            </td>
                        </template>
                        <template #fantasy_name="{item}" v-else>
                            <td v-if="item.fantasy_name.length > 15">
                              {{item.fantasy_name.substring(0,15 )}}
                              <a v-c-tooltip.hover.click="item.fantasy_name">
                                ...
                              </a>
                            </td>
                            <td v-else>
                              {{item.fantasy_name}}
                            </td>
                        </template>
                        <template #company_name="{item}" v-if="isFullDesktop">
                            <td v-if="item.company_name.length > 35">
                              {{item.company_name.substring(0,35)}}
                              <a v-c-tooltip.hover.click="item.company_name">
                                ...
                              </a>
                            </td>
                            <td v-else>
                              {{item.company_name}}
                            </td>
                        </template>
                        <template #company_name="{item}" v-else>
                            <td v-if="item.company_name.length > 20">
                              {{item.company_name.substring(0,20)}}
                              <a v-c-tooltip.hover.click="item.company_name">
                                ...
                              </a>
                            </td>
                            <td v-else>
                              {{item.company_name}}
                            </td>
                        </template>
                    </CDataTable>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCollapse>  
           
          </CCard>
        </transition>
      </CCol>
    </CRow>
    <CRow v-else>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <CIcon name="cil-building" /> Empresas
            <div class="card-header-actions">
              <CLink href="#" class="card-header-action btn-close" @click="viewFieldMode=false">
                <CIcon name="cil-x-circle"/>
              </CLink>
            </div>
          </CCardHeader>          
            <CCardBody>
              <CRow class="align-items-center">
                <CCol  sm="3" md="2" class="mb-3 mb-xl-0">
                  <CButton block color="dark" shape="pill" @click="viewFieldMode = false">Desistir</CButton>
                </CCol>
                <CCol  sm="3" md="2" class="mb-3 mb-xl-0">
                  <CButton block color="success" shape="pill" @click="SalvarAlteracao">Salvar</CButton>
                </CCol>
              </CRow>
              <CRow class="align-items-center mt-3">
                <CCol  sm="12" class="mb-3 mb-xl-0">
                  <CTabs  :active-tab.sync="activeTabView" variant="pills">
                    <CTab>
                      <template slot="title">
                        <CIcon name="cil-building" /> Empresa
                      </template>
                        <CTabs>
                          <CTab>
                            <template slot="title">
                              Básico
                            </template>
                            <CadEmpresaBasico :form="form" :chkIE="chkIE" :chkIM="chkIM" />
                          </CTab>
                          <CTab>
                            <template slot="title">
                              Outras Inf.
                            </template>
                            <CadEmpresaOutInformacoes :form="form" />
                          </CTab>
                        </CTabs>                      
                    </CTab>
                    <CTab>
                      <template slot="title">
                        <CIcon name="cil-dollar"/> Taxa
                      </template>
                      <CadEmpresaTaxa :form="form" :readOnly="true" />
                    </CTab>                   
                    <CTab>
                      <template slot="title">
                        <CIcon name="cil-address-book"/> Endereço
                      </template>
                      <CadEmpresaEndereco :form="form" :readOnly="true" />                      
                    </CTab>
                    <CTab>
                      <template slot="title">
                        <CIcon name="cil-user"/> Responsável
                      </template>
                      <CTabs>
                        <CTab>
                            <template slot="title">
                            Dados
                            </template>
                            <CadEmpresaResponsavel :form="form" />
                        </CTab>
                        <CTab>
                          <template slot="title">
                            Endereço
                          </template>
                          <CadEmpresaEndResponsavel :form="form" />
                        </CTab>
                      </CTabs>
                    </CTab>
                    <CTab>
                      <template slot="title">
                        <CIcon name="cil-address-book"/> Documentos
                      </template>
                      <CadEmpresaDocumento :form="form" v-if="(activeTabView===4)" />
                    </CTab>
                    <CTab>
                      <template slot="title">
                        <CIcon name="cil-image"/> OCR
                      </template>
                      <CadEmpresaOCR :ocr="form.ocr" />
                      <h5 class="pt-3">Observações:</h5>
                      <p class="text-muted">Para o OCR funcionar corretamente, as imagens devem ser enviadas em formato JPG ou JPEG.</p>
                      <p class="text-muted">Os arquivos devem ser renomeadas para self, cnh, rg_frente e rg_verso. Exemplo:  "self.jpg", "cnh.jpg", "rg_frente.jpg" e "rg_verso.jpg".</p>
                      <p class="text-muted">Apenas seram aceitos documentos do tipo RG ou CNH, optando entre os mesmos.</p>
                      <p class="text-muted">Todos os arquivos juntos não devem ultrapassar 400KB</p>
                    </CTab>
                    <CTab>
                      <template slot="title">
                        <CIcon name="cil-bank"/> eConta
                      </template>
                      <CadEmpresaConta 
                        :form="form" 
                        :bankAccount="bankAccount" 
                        :keysPix="keysPix" 
                        :upKeysPIX="upKeysPIX"
                        :newKeyPix="newKeyPix"
                        :createAccount="createAccount"
                        :updateAccount="updateAccount"
                        :reSendAccount="reSendAccount" />
                    </CTab>
                    <CTab>
                      <template slot="title">
                        <CIcon name="cil-user-follow"/> Acesso
                      </template>
                      <CadEmpresaUsuarios :form="form" :onError="onError" v-if="(activeTabView===7)"/>
                    </CTab>
                  </CTabs>
                </CCol>
              </CRow>
            </CCardBody>          
        </CCard>       
      </CCol>
    </CRow>

    <WizardCompany :WizardNewCompany="onWizardNewCompany" :onClose="closeWizard" :onReload="getData" />
    
    <CModal title="Aviso!" color="danger" :show.sync="showAlert">
      {{ msg }}
      <template #footer>
        <CButton @click="showAlert = false" color="danger">OK</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import functions from "@/functions";
const axios = require("axios").default;
import { formDefault } from "./variables"
//import "regenerator-runtime";
import WizardCompany from './Wizard.vue';
import CadEmpresaBasico from './Tabs/Basico';
import CadEmpresaOutInformacoes from './Tabs/OutInformacoes';
import CadEmpresaEndereco from './Tabs/Endereco';
import CadEmpresaResponsavel from './Tabs/Responsavel';
import CadEmpresaEndResponsavel from './Tabs/EndResponsavel';
import CadEmpresaConta from './Tabs/Contas';
import CadEmpresaDocumento from './Tabs/Documentos';
import CadEmpresaUsuarios from './Tabs/Usuarios';
import CadEmpresaTaxa from './Tabs/Taxa';
import CadEmpresaOCR from './Tabs/OCR';

export default {
  name: 'Empresas',
  components:{
    WizardCompany,
    CadEmpresaBasico,
    CadEmpresaOutInformacoes,
    CadEmpresaEndereco,
    CadEmpresaResponsavel,
    CadEmpresaEndResponsavel,
    CadEmpresaConta,
    CadEmpresaDocumento,
    CadEmpresaUsuarios,
    CadEmpresaTaxa,
    CadEmpresaOCR
  },
  data () {
    return {
      viewFieldMode: false,
      formCollapsed: true,      
      items: [],
      dataTable: [],
      bankAccount: [],
      showAlert: false,
      onWizardNewCompany: false,
      msg:'',
      fields: [
        {key: 'company_name', label: 'Razão Social'}, 
        {key: 'fantasy_name', label: 'Fantasia'}, 
        {key: 'cnpj'        , label: 'CNPJ'},
        {key: 'active'      , label: 'Ativo'}, 
        {key: 'actions'     , label: 'Ações'}
      ],
      fieldsRepresentatives: [
        {key: 'name'   , label: 'Nome'},
        {key: 'tax_id' , label: 'CPF'},
        {key: 'phone'  , label: 'Telefone'},
        {key: 'email'  , label: 'Email'},
        {key: 'actions', label: 'Ações'}
      ],
      caption: 'Empresas',
      hover: false,
      striped: false,
      border: false,
      small: false,
      fixed: false,
      dark: false,
      noItemsView:{ 
          noResults: 'Nenhum resultado para este filtro', 
          noItems: 'Nenhuma empresa cadastrada' 
      },
      noRepresView:{ 
          noResults: 'Nenhum resultado para este filtro', 
          noItems: 'Nenhum representante cadastrado' 
      },
      activeTabView: 0,
      isMobile: false,
      isFullDesktop: false,
      chkIE: false,
      chkIM: false,  
      form: new formDefault(),
      keysPix:[]
    }
  },
  created: function () { 
    this.getData();
  },
  methods: {
    ...functions,   
    Fechar: function () {
       this.$router.push('/');
    },
    SalvarAlteracao: function () {
      const token = localStorage.getItem('user-token');
      console.log('this.form',this.form);
      axios({
        method: 'put',
        url: this.vhost(`company/cnpj/${this.handlingCNPJ(this.form.cnpj)}`),
        headers: { "Content-Type": "application/json","x-access-token": token},
        data: this.form,
        charset: 'utf-8',
      })
      .then(() => {
        this.viewFieldMode = false
      })
      .catch(this.onError);
    },
    getData: function () {
      const token = localStorage.getItem('user-token');
      axios({
        method: 'get',
        url: this.vhost('company'),
        headers: { "Content-Type": "application/json","x-access-token": token},
      })
      .then((res) => {       
        this.items=res.data.data;
      })
      .catch(this.onError);
    },
    getDataFullData (cnpj) {
      const token = localStorage.getItem('user-token');
      axios({
        method: 'get',
        url: this.vhost(`company/cnpj/${cnpj}`),
        headers: { "Content-Type": "application/json", "x-access-token": token },
        responseType: 'json',
        charset: 'utf-8',
        responseEncodig: 'utf-8',
      })
      .then((res) => {        
        this.form=  res.data.data;
        if (res.data.data.account && (res.data.data.account.length>0)) {
          this.bankAccount = res.data.data.account
        } else {
           this.bankAccount = []
        }
        this.form.billing_address.zip_code = this.form.billing_address.zip_code.replace(/(\d{5})(\d{3})/, "$1-$2");
        this.form.representatives[0].mail_address.zip_code = this.form.representatives[0].mail_address.zip_code.replace(/(\d{5})(\d{3})/, "$1-$2");
        this.form.billing_address.uf = this.stateToUF(this.form.billing_address.state)
        this.form.representatives[0].mail_address.uf = this.stateToUF(this.form.representatives[0].mail_address.state)   
        this.chkIE = (this.form.ie==='ISENTO');
        this.chkIM = (String(this.form.im)==='ISENTO');
        this.form.phone= this.form.phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");
        this.form.representatives[0].phone = this.form.representatives[0].phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");
        console.log('this.form:', this.form);
        this.viewFieldMode = true;        
      })
      .catch(this.onError);
    },
    getBadge (status) {
      switch (status) {
        case true: return 'success'
        case false: return 'secondary'
        default: 'primary'
      }
    },
    setAlert: function (message){
      this.msg=message;
      this.showAlert=(message !== undefined);
    },
    onResize () {
        this.isMobile = window.innerWidth < 576;
        this.isFullDesktop = window.innerWidth > 1300;
    },
    deleteCompany(cnpj){
      const token = localStorage.getItem('user-token');
      axios({
        method: 'delete',
        url: this.vhost(`company/cnpj/${cnpj}`),
        headers: { "Content-Type": "application/json","x-access-token": token},
      })
      .then(() => {        
        this.getData();
      })
      .catch(this.onError);
    },
    editCompany (cnpj) {
      this.activeTabView=0;
      this.clearForm();      
      this.getDataFullData(cnpj);
      this.getKeysPIX(cnpj);
    },
    newDocument(){
      this.clearForm();
      this.onWizardNewCompany=true;
    },
    getKeysPIX(cnpj){
      const doc = this.handlingCNPJ(cnpj)
      const token = localStorage.getItem('user-token');
      axios({
        method: 'get',
        url: this.vhost(`pix/${doc}`),
        headers: { "Content-Type": "application/json","x-access-token": token},
      })
      .then(con=>{
        try {
          this.keysPix=con.data.data.pix;          
        } catch  {
          this.keysPix=[]
        }
      })
      .catch(this.onError)
    },
    clearForm(){
      this.form = new formDefault();
    },
    closeWizard(){
      this.onWizardNewCompany=false
    },
    upKeysPIX(cnpj){
      const doc = this.handlingCNPJ(cnpj)
      const token = localStorage.getItem('user-token');
      axios({
          method: 'put',
          url: this.vhost(`pix/${doc}`),
          headers: { "Content-Type": "application/json","x-access-token": token},
      })
      .then(con=>{
          if (con.data.data.pix) {
              this.keysPix= con.data.data.pix;
          }
      })
      .catch(this.onError)
    },
    newKeyPix(cnpj){
        const doc = this.handlingCNPJ(cnpj)
        const token = localStorage.getItem('user-token');
        axios({
            method: 'post',
            url: this.vhost(`pix/${doc}`),
            headers: { "Content-Type": "application/json","x-access-token": token},
        })
        .then(()=>{
            try {
                throw new Error('Solicitação em andamento. Atualize mais tarde.');
            } catch (error) {
                this.onError(error);
            }
        })
        .catch(this.onError)
    },
    createAccount(cnpj){
        if (!cnpj || cnpj===''){        
            return this.setAlert('CNPJ invádo');
        }
        const doc = this.handlingCNPJ(cnpj)
        const token = localStorage.getItem('user-token');
        axios({
            method: 'post',
            url: this.vhost('account'),
            headers: { "Content-Type": "application/json","x-access-token": token},
            data:{cnpj:doc}
        })
        .then((res) => {
            this.bankAccount= res.data.data;
        })
        .catch(this.onError);
    },
    updateAccount(cnpj){
        const sCnpj = this.handlingCNPJ(cnpj)
        const token = localStorage.getItem('user-token');
        axios({
            method: 'put',
            url: this.vhost(`account/cnpj/${sCnpj}`),
            headers: { "Content-Type": "application/json","x-access-token": token},
        })
        .then((res) => {
            this.bankAccount=res.data.data;
        })
        .catch(this.onError);
    },
    reSendAccount(cnpj) {
        const sCnpj = this.handlingCNPJ(cnpj)
        const token = localStorage.getItem('user-token');
        axios({
            method: 'post',
            url: this.vhost(`account/resend/cnpj/${sCnpj}`),
            headers: { "Content-Type": "application/json","x-access-token": token},
        })
        .then((res) => {
            this.bankAccount=res.data.data;
        })
        .catch(this.onError);
    },
    onError(err){
      this.$loading(false);
      if (err.response && err.response.data) {
          if (err.response.data.auth===false) {
            localStorage.removeItem('user-token');
            localStorage.removeItem('user-data');
            this.$router.push('/admin/login');
          } else if (err.response && err.response.data.erro){
             this.setAlert(err.response.data.erro); 
          } else {
            this.setAlert(err.message);
          }
      } else if(typeof(err)==='string') { 
        this.setAlert(err);
      } else {
        this.setAlert(err.message);
      }
    },
  },
  mounted () {
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
  },
}
</script>
